<template>
    <div>
        <!--<PageHeader :titulo="titulo"/>-->
        <titulo-seccion>
            <template slot="area-rutas">
                <a href="/">Inicio</a> / <span class="active">Transmisiones</span>
            </template>
            <template slot="area-titulo">
                <h3 class="text-center">Transmisiones</h3>
            </template>
        </titulo-seccion>
        <section class="noticias">
            <div class="container">
                <div class="row">
                    <div id="box-transmision" class="col-md-8 col-sm-12 p-0">
                        <img v-if="vitem.logo" v-lazy="vitem.logo" :alt="vitem.titulo">
                        <!-- <div class="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                            <iframe v-if="video" class="embed-responsive-item" :src="video" allowfullscreen></iframe>
                        </div> -->
                        
                            <b-embed 
                                class="embed-responsive embed-responsive-16by9"
                                v-if="video"
                                type="iframe" 
                                aspect="16by9"
                                :allowfullscreen="true" 
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                :src="video"
                            />
                        
                    </div>
                    <div class="col-md-4 col-sm-12  p-0 d-none d-md-block">
                        <div class="pl-4 p-2" id="scrollspy-example">
                            <ul class="media-list ">
                                <b-media tag="li" v-for="(item, index) in items" :key="index" class="col-xs-12">
                                    <template #aside >
                                        <img 
                                            v-if="item.logo"
                                            v-lazy="item.logo" 
                                            width="64" alt="placeholder"
                                        > 
                                        <!-- <b-img v-if="item.logo" :src="item.logo" width="64" alt="placeholder"></b-img> -->
                                        <b-img v-else src="images/live.jpg" width="64" alt="placeholder"></b-img>
                                    </template>
                                    <p @click="selectVideo(item)" class="card-title cursor"><strong>{{item.titulo}}</strong></p>
                                    <small class="text-muted">{{item.published_at}}</small>
                                    <button @click="selectVideo(item)" class="btn btn-icon btn-fiscalia float-end"><i class="icon icon-white">
                                        <svg width="10" height="10" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M0 0V24L20 12L0 0Z" fill="white"/>
                                        </svg>
                                        </i>
                                    </button>
                                </b-media>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-md-none d-lg-none d-block">
                    <hr>
                    <h6>TRANSMISIONES</h6>
                    <b-card no-body class="border">
                        <div class="media-list p-2">
                            <b-media v-for="(item, index) in items" :key="index" >
                                <template #aside>
                                    <div @click="selectVideo(item)" class="box-imagen box-imagen1 rounded cursor">
                                        
                                        <b-img 
                                            v-if="item.logo"
                                            center
                                            fluid
                                            thumbnail
                                            :src="item.logo"
                                            v-lazy="item.logo"  
                                            class="img-fluid img-responsive cursor" 
                                            :alt="item.titulo" 
                                            :title="item.titulo"
                                        /> 
                                        <b-img
                                            v-else
                                            center
                                            fluid
                                            thumbnail
                                            src="images/live.jpg"
                                            class="img-fluid img-responsive cursor" 
                                            :alt="item.titulo" 
                                            :title="item.titulo"
                                        /> 
                                    </div>
                                    
                                </template>
                                <div @click="selectVideo(item)">
                                    <h6 class="item-name">
                                        {{item.titulo}}
                                    </h6>
                                </div>
                            </b-media>
                        </div>
                    </b-card>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios'
//import PageHeader from '/src/components/PageHeader.vue';
import {BMedia, BImg   } from "bootstrap-vue";
import TituloSeccion from '@/views/secciones/TituloSeccion.vue'
export default {
    components:{
        //PageHeader,
        // BEmbed,
        BMedia,
        BImg,
        TituloSeccion
    },
    directives: { 

    },
    data(){
        return{
            titulo: "Transmiciones",
            items:[],
            video:'',
            buscar:'',
            vitem: null,
        }
    },
    metaInfo: {
        title: 'Ministerio Público',
        titleTemplate: '%s | Transmiciones',
        htmlAttrs: {
        lang: 'es-ES'
        },
        meta: [
        { name: 'description', content: 'Transmiciones' }
        ]
    },
    created(){       
        this.listarVideos();
    },
    watch: {
        buscar: function(val) {
            if (val == "" || val.length == 0) {
                this.listarVideos();
            }
        },
    },

    methods: {
        listarVideos() {
            var url= '/admin/transmisiones';
            axiosIns.get(url)
            .then(res => {
                this.items = res.data
                if (this.items.length) {
                    this.video=this.items[0].url;
                    this.vitem=this.items[0];
                }
            })
            .catch(err =>{
                console.log(err);
            });
        },
        selectVideo(data){
            //console.log(data)
            this.vitem = data;
            this.video=data.url;            
        },
        buscarVideoList() {
            this.listarVideos()
        },
  },

}
</script>

<style>
.cursor{
    cursor: pointer;
}
.media-bordered .media:not(:first-child) {
    border-top: 1px solid #ebe9f1;
}
.card-title{
    font-size: 14px;
}
.box-imagen {
  height: 15rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
@media (max-width: 700px) {
  .box-imagen {
    height: 5rem;
  }
  .embed-responsive-4by3 {
      height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
  }
}
</style>
